import { Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, IconButton, InputAdornment, MenuItem, Slider, TextField, Typography } from '@mui/material';
import './App.css';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { db } from "./firestore.js";
import { setDoc ,doc ,addDoc, collection} from 'firebase/firestore';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function App() {
  //infomassages 
  const paymanageinfomassage =
    "金銭管理費用は、毎月200円です。保佐人様,親御様,桐生市社会福祉協議会などの関係機関との連携を図り,主に毎月どのくらいをお小遣いとして使うか、使いすぎていないかなどの相談をしながら、金銭管理を行います。主に金銭の受け取りを施設が行う場合などに金銭管理費として請求します";
  const dinnermassage = "夕食代は、550円です。朝食は,350円です。施設での手作りでの提供です。";
  const Tabletmassage = "共益費は, 6000円です。共益費の内訳は、家具家電貸し出し、タブレット貸し出し、Wifi使用量です。";
  const nightsupportmsg = "夜間の見守りは、障がい福祉サービスの一つです。主に夜間における緊急時の対応や、深夜にお部屋の前に行き見回りを行います。利用者負担は,1割です。生活保護受給世帯、住民税非課税世帯は、利用者負担0円になります。区分に応じて請求額が代わりますが、区分3の利用者様の場合は,おおよそ1日あたり112円ほどの負担額になります。";

  //Dialog control
  const [Reviewopen,setReviewopen] = useState(false);
  const [open, setOpen] = useState(false);
  const [infoopen, setinfoopen] = useState(false);
  const [infomsg, setinfomassage] = useState("");
  function handleReviewClickOpen() {
    setReviewopen(true);
  };

  function handleReviewClose() {
    setReviewopen(false);
  };
  function handleinfoClickOpen() {
    setinfoopen(true);
  };

  function handleinfoClose() {
    setinfoopen(false);
  };


  function handleClickOpen() {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
  };

  //value　control
  const [Review,setReview] = useState("");
  const [subscribe  ,setsubscribe] = useState(true);
  const [saveemail, setsaveemail] = useState("");
  const [myjob, setmyjob] = useState("");
  const [userstatus, setuserstatus] = useState("");
  const [hdcpnote, sethdcpnote] = useState(false);
  const [pension, setpension] = useState();
  const [Counselor, setConunselor] = useState("");
  const [Servicehiyou, setServicehiyou] = useState(0);
  const [limitvalue, setlimitvalue] = useState(37200);
  const [kubunn, setkubunn] = useState();
  const [morningvalue, setmorningvalue] = useState(0);
  const [dinnervalue, setdinnervalue] = useState(0);
  const [Name, setName] = useState("なまえ");
  const [pay, setpay] = useState(0);
  const [rent, setrent] = useState(0);
  const [carrent, setcarrent] = useState(0);
  const [paymanage, setpaymanage] = useState(0);
  const [nightsupport, setnightsupport] = useState(false);

  const handleChange_morning = (event, newValue) => {
    if (typeof newValue === 'number') {
      setmorningvalue(newValue * 350);
    }
  };

  const handleChange_dinner = (event, newValue) => {
    if (typeof newValue === 'number') {
      setdinnervalue(newValue * 550);
    }
  };

  const handleChange_rent = (event, newValue) => {
    if (event == "一号棟") {
      setrent(27000);
    } else if (event == "二号棟") {
      setrent(30000);
    } else {
      setrent(30000);
    }
  };
  const handleChange_carrent = (event) => {
    if (event == true) {
      setcarrent(3000);
    }
    else if (event == false) {
      setcarrent(0)
    }
  };

  const handleChange_paymanage = (event) => {
    if (event == true) {
      setpaymanage(200);
    }
    else if (event == false) {
      setpaymanage(0)
    }
  };
  const handleChange_nightsupport = (event) => {
    if (nightsupport == true) {
      setnightsupport(false);
    }
    if (nightsupport == false) {
      setnightsupport(true);
    }
    console.log(nightsupport);
  };

  function senduserdata() {
    setDoc(doc(db, "userdata", Name), {
      メール : Name,
      障害区分 : kubunn,
      相談員の有無 : Counselor,
      役割  : userstatus,
      前年度の年収 : pay,
      障害者手帳の有り無し : hdcpnote,
      メルマガの希望　: subscribe
    }); 
  }

  function sendReview() {
    addDoc(collection(db, "Review"), {
      内容 : Review 
    }); 
  }


  function syogaiServicehi(haiti, taisyousya, day) {
    const seiennhaitikouzou = [[243, 292, 381, 471, 552, 667], [198, 243, 331, 421, 500, 616], [170, 209, 298, 387, 467, 583]];
    const yakannsiennkouzou = [[448, 560, 672], [299, 373, 448], [224, 280, 336], [179, 224, 269], [149, 187, 224], [128, 160, 192], [112, 140, 168], [99, 124, 149], [90, 113, 135], [81, 102, 122], [75, 93, 112], [69, 86, 103], [64, 80, 96], [60, 75, 90], [56, 70, 84], [53, 66, 79], [50, 63, 75], [47, 59, 71], [45, 56, 67], [43, 53, 64], [41, 51, 61], [39, 48, 58], [37, 47, 56], [36, 45, 54], [34, 43, 51], [33, 42, 50], [32, 40, 48], [31, 38, 46], [30, 38, 45]]
    const seiennhi = seiennhaitikouzou[haiti - 4][kubunn - 1] * day;

    senduserdata();
    if (kubunn < 3) {
      setServicehiyou(seiennhi + (yakannsiennkouzou[taisyousya - 2][0] * day));
    }
    if (kubunn == 3) {
      setServicehiyou(seiennhi + (yakannsiennkouzou[taisyousya - 2][1] * day));
    }
    if (kubunn > 3) {
      setServicehiyou(seiennhi + (yakannsiennkouzou[taisyousya - 2][2] * day));
    }
    if (limitvalue == 0) {
      if (rent == 27000) {
        setrent(17000);
      } else if (rent == 30000) {
        setrent(20000);
      }
      setServicehiyou(0);
    }
  }
  const currencies = [
    {
      label: '6',
    },
    {
      label: '5',
    },
    {
      label: '4',
    },
    {
      label: '3',
    },
    {
      label: '2',
    },
    {
      label: '1',
    },
  ];
  const homes = [
    {
      label: '一号棟',
      address: '群馬県桐生市東1丁目１０－８ アリスA棟 '
    },
    {
      label: '二号棟',
      address: '群馬県桐生市浜松町2丁目７－２２ 桐生レジデンス'
    },
    {
      label  : 'どちらでも可能',
      address: '群馬県桐生市浜松町2丁目７－２２ 桐生レジデンス'
    }
  ];

  const userstate = [
    {
      label : '入居者'
    },
    {
      label : '支援者'
    }
  ];
  const yesorno = [
    {
      label : 'あり'
    },
    {
      label : 'なし'
    }
  ];

  return (
    <div >
      <div className="Appview">
        <img style={{ height: "10vh", width: "10vh" }} src="https://static.wixstatic.com/media/7bbed0_9a0b1d6d1d4f4bbd9d565d16b82ba812~mv2.jpg/v1/fill/w_246,h_246,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/rogo%20kana%20icon.jpg" />
        <h1> 真友テラス桐生　<br></br>利用料金シュミレーション</h1>
        <p>ここで記入いただく個人情報はプラウザを閉じると消去されますのでご安心ください。
          サービス費用は、国保連の資料を元に作成しています。
        </p>
        <h2 >シミュレートのご本人情報をご記入ください</h2>
        <p className="importantmassage">必須</p>
        <TextField
          style={{ marginTop : "1vh"}}
          id="outlined-select-currency"
          select
          label="支援者か入居希望者か"
          value={userstatus}
          helperText="支援者の方でしょうか？入居希望の方でしょうか？？"
          onChange={(e) => setuserstatus(e.target.value)}
        >
          {userstate.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
         <p className="importantmassage">任意</p>
        <TextField onChange={(e) => { setName(e.target.value) }} sx={{ mb: "5vh" }} type="email" label="メールアドレス" helperText="お使いのメールアドレスを入力してください。" >
        </TextField>
       
        <p className="importantmassage">任意</p>
        <TextField onChange={(e) => { setpay(e.target.value) }} InputProps={{
          endAdornment: <InputAdornment position="start">円</InputAdornment>,
        }} sx={{ mb: "5vh" }} label="前年度のおおよその年収" helperText="ご自身の前年度のおおよその年収を入力してください (例　: 800000円)" >
        </TextField>
        <p className="importantmassage">必須</p>
        <TextField
         style={{ marginTop : "1vh"}}
          label="障害支援区分"
          id="outlined-select-currency"
          select
          value={kubunn}
          helperText="区分を選択してください"
          onChange={(e) => setkubunn(e.target.value)}
        >
          {currencies.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <p className="importantmassage">必須</p>
        <TextField
          style={{ marginTop : "1vh"}}
          id="outlined-select-currency"
          select
          label="障害者手帳の有無"
          value={hdcpnote}
          helperText="障害者手帳の有無を記入してください"
          onChange={(e) => sethdcpnote(e.target.value)}
        >
          {yesorno.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <p className="importantmassage">必須</p>
        <TextField
          style={{ marginTop : "1vh"}}
          id="outlined-select-currency"
          select
          label="相談員の有り無し"
          value={Counselor}
          helperText="相談員の有無を記入"
          onChange={(e) => setConunselor(e.target.value)}
        >
          {yesorno.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>







        <h2 >当てはまる項目にチェックしてください</h2>
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={(e) => { e.target.checked == true ? setlimitvalue(0) : setlimitvalue(37200) }} />} label="生活保護受給中である。または市町村民非課税世帯である" />
          <FormControlLabel control={<Checkbox onChange={(e) => { e.target.checked == true ? setpension(true) : setpension(false) }} />} label="障害年金受給中である" />
        </FormGroup>
        <h2 >受けたいサービスを選択してください</h2>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label="食事提供をお願いしたい" />
          <p>{dinnermassage}</p>
          <h4>朝食回数 (月)</h4>
          <div>毎月、朝食は{morningvalue / 350}回摂る</div>
          <p className="importantmassage">※青●を移動して下さい</p>
          <Slider
            defaultValue={0}
            step={1}
            marks
            min={0}
            max={31}
            onChange={handleChange_morning}
          />
          <h4>夕食回数  (月)</h4>
          <div>毎月、夕食は{dinnervalue / 550}回摂る</div>
          <p className="importantmassage">※青●を移動して下さい</p>
          <Slider
            defaultValue={0}
            step={1}
            marks
            min={0}
            max={31}
            onChange={handleChange_dinner}
          />
       
              <FormControlLabel control={<Checkbox onChange={(e) => handleChange_paymanage(e.target.checked)} />} label="金銭管理を施設にお願いしたい" />
              <p>{paymanageinfomassage}</p>
         
          <FormControlLabel control={<Checkbox onChange={handleChange_nightsupport} />} label="夜間はしっかり見守ってほしい" />
          <p>{nightsupportmsg}</p>

          <FormControlLabel control={<Checkbox />} label="タブレット貸し出しをお願いしたい" />
          <FormControlLabel control={<Checkbox />} label="基本的な家具貸し出しをお願いしたい" />
          <FormControlLabel control={<Checkbox />} label="Wifiを使用したい" />
          <p>{Tabletmassage}</p>
        </FormGroup>
        <h2 >ご希望の棟をお選びください</h2>
        <TextField
          id="outlined-select-currency"
          select
          label="入居棟"
          defaultValue=""
          onChange={(e) => handleChange_rent(e.target.value)}
          helperText="入居希望の棟を選んでください"
        >
          {homes.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
              <p>{option.address}</p>
            </MenuItem>
          ))}
        </TextField>
        <p>{rent}</p>
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={(e) => { handleChange_carrent(e.target.checked) }} />} label="駐車場をお借りしたい" />
          <p>駐車場代は、毎月3000円になります。</p>
          <p>{carrent}</p>
        </FormGroup>
        <h2 >最後に確認をお願いします</h2>
        <FormGroup>
          <FormControlLabel  control={<Checkbox defaultChecked value={subscribe} onChange={(e) => { setsubscribe(e.target.checked) }} />} label="メールマガジン登録を許可する" />
          <p>記入いただいたメールアドレスにメールマガジンを希望する</p>
          <p>主に、毎月のお知らせ。広報紙の配布。ブログ更新の通知などに使用させていただきます</p>
        </FormGroup>
        <button onClick={() => { handleClickOpen(); syogaiServicehi(4, 11, 31); }} className='SimulationButton ' >シミュレーションする</button>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <IconButton
              onClick={handleClose}
            >
              <CloseIcon />
        </IconButton>
        <DialogContentText sx={{padding:0}}><h1 id="scroll-dialog-title">シミュレーション結果</h1></DialogContentText>
        <DialogContent>
          <h5>毎月の費用</h5>
          <h3 className="hiyou_text">{morningvalue + dinnervalue + rent + carrent + 6000 + Servicehiyou}</h3>
          <h5>家賃 : {rent}</h5>
          <h5>朝食代 : {morningvalue}</h5>
          <h5>夕食代 : {dinnervalue}</h5>
          <h5>共益費 : 6000円</h5>
          <h5>駐車場代 : {carrent}</h5>
          <h5>サービス費用 : {limitvalue == 0 ? 0 : Servicehiyou}</h5>
          <h5 style={{ color: "red" }}>毎月の収入 : { !0 ? pay / 12 : 0}</h5>
          {pay / 12 < Servicehiyou || pay == 0 ? (<h2>収入よりも費用のほうが多くかかります</h2>) : (<h2>収入のほうが料金より多くて安心ですね！</h2>)}
          <h2>お問い合わせはHP　もしくはお電話　mailにてお待ちしております！</h2>
          <h2><a href='tel: 08094153969'> 真友テラス桐生に電話をかける</a></h2>
          <h2><a target="_blank" href='https://www.shinyouterrace-kiryu.com/contact'> お問い合わせページに飛ぶ</a></h2>
          <h2>ご感想やご要望などあればぜひご記入ください</h2>
          <TextField
          fullWidth
          id="outlined-multiline-static"
          label="感想"
          multiline
          rows={10}
          onChange={(e) => { setReview(e.target.value) }} 
        />
        <button onClick={() => {sendReview();handleReviewClickOpen()}} className='SimulationButton '>送信する</button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={Reviewopen}
        // TransitionComponent={Transition}
        //keepMounted
        onClose={handleReviewClose}
      >
        <DialogTitle>送信しました！ありがとうございます！</DialogTitle>
        </Dialog>
    </div>
  );
}

export default App;
