import "./App.css";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

//firebaseと接続./componets.jsx
const firebaseConfig = {
    apiKey: "AIzaSyCOM2JuNc9tGjbEKR59m9HhYW8Rpr_xG9g",
    authDomain: "shinterrahp.firebaseapp.com",
    projectId: "shinterrahp",
    storageBucket: "shinterrahp.appspot.com",
    messagingSenderId: "123776981514",
    appId: "1:123776981514:web:1014e2d704c5c94c68ddf3",
    measurementId: "G-7DVL0NW01K"
  };
//appにfirebaseの情報入れてる
const app = initializeApp(firebaseConfig);
//firestoreのdbを入れてる
const db = getFirestore(app);

export { db };